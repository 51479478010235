import { useState, useEffect } from 'react'
import { defaultColumnVisibilityModel } from './columns'

export const useColumnVisibilityModel = () => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    defaultColumnVisibilityModel
  )
  const providerId = sessionStorage.getItem('providerID')
  const key = `${providerId}-columnVisibilityModel`

  useEffect(() => {
    const localColumnVisibilityModel = localStorage.getItem(key)
    if (localColumnVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(localColumnVisibilityModel))
    }
  }, [])

  const setState = (visibilityModel) => {
    localStorage.setItem(key, JSON.stringify(visibilityModel))
    setColumnVisibilityModel(visibilityModel)
  }

  const isBloodPressureVisible = () => {
    const { bptaken, bpThresholdLevel, recentBp, pulse, bpDaysWithReadings } =
      columnVisibilityModel
    return (
      bptaken || bpThresholdLevel || recentBp || pulse || bpDaysWithReadings
    )
  }

  const isWeightScaleVisible = () => {
    const { weighttaken, weight, weightChange, recentWs, wsDaysWithReadings } =
      columnVisibilityModel
    return (
      weight || weightChange || weighttaken || recentWs || wsDaysWithReadings
    )
  }

  const isSpo2Visible = () => {
    const { spo2Date, spo2, pulseRate, recentPulse, pulseDaysWithReadings } =
      columnVisibilityModel
    return spo2 || spo2Date || recentPulse || pulseRate || pulseDaysWithReadings
  }

  const isGlucoseVisible = () => {
    const { glucose, glucoseDate, recentGlucose, glucoseDaysWithReadings } =
      columnVisibilityModel
    return glucose || glucoseDate || recentGlucose || glucoseDaysWithReadings
  }

  return {
    columnVisibilityModel,
    setColumnVisibilityModel: setState,
    isBloodPressureVisible,
    isWeightScaleVisible,
    isSpo2Visible,
    isGlucoseVisible,
  }
}
