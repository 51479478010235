import moment from 'moment-timezone'
import { unionWith, isEqual } from 'lodash'
import { pascalsToTorr } from '../../common/conversions'
import { CARDIOWELL, TRANSTEK, WITHINGS, AD } from '../../common/manufacters'
import { parceBerryMedOximeterTime } from '../../helpers/parceBerryMedOximeterTime'
import { getBpThresholdLevel } from '../../thresholds/threshold/getBpThresholdLevel'
import { standardizeAdBpm } from '../../measurements/bpm/standardizeAdBpm'
import { standardizeBtMessageWs } from '../../measurements/ws/standardizeBtMessageWs'
import { standardizeBtWs } from '../../measurements/ws/standardizeBtWs'
import { standardizeTranstekWs } from '../../measurements/ws/standardizeTranstekWs'

const countDaysWithReadings = (daysWithReadings) =>
  daysWithReadings.reduce((accumulator, { _id }) => {
    if (_id && _id.day && _id.month && _id.year) {
      return accumulator + 1
    }
    return accumulator
  }, 0)

const joinDaysWithReadings = (a, b) => unionWith(a, b, isEqual)

export const parsePatients = (patients) => {
  const tableData = []
  const user = sessionStorage.getItem('user')

  patients.forEach((patient) => {
    const bpDevice = patient.selectedBpDevice ?? CARDIOWELL
    const weightDevice = patient.selectedWeightDevice ?? CARDIOWELL

    let systolic = ''
    let diastolic = ''
    let bpDate = ''
    let pulse = ''
    let recentBp = 0
    let bpDaysWithReadings = 0

    let weight = ''
    let weightChange = 0
    let wsDate = ''
    let recentWs = 0
    let wsDaysWithReadings = 0

    let glucose = ''
    let glucoseDate = ''
    let recentGlucose = 0
    let glucoseDaysWithReadings = 0

    let spo2 = ''
    let spo2Date = ''
    let pulseRate = ''
    let recentPulse = 0
    let pulseDaysWithReadings = 0

    // BPM data
    if (bpDevice === TRANSTEK && patient.ttBpm.length) {
      // Transtek
      systolic = parseInt(patient.ttBpm[0].systolic)
      diastolic = parseInt(patient.ttBpm[0].diastolic)
      bpDate = patient.ttBpm[0].ts * 1000
      pulse = patient.ttBpm[0].pulse
      recentBp = patient.ttRecentBpms
      bpDaysWithReadings = countDaysWithReadings(patient.ttBpmDaysWithReadings)
    } else if (
      bpDevice === CARDIOWELL &&
      (patient.bpm.length || patient.btMessagesBpm.length)
    ) {
      // Bodytrace
      if (patient.bpm.length) {
        systolic = pascalsToTorr(patient.bpm[0].systolic)
        diastolic = pascalsToTorr(patient.bpm[0].diastolic)
        bpDate = moment(patient.bpm[0]._created_at).valueOf()
        pulse = patient.bpm[0].pulse
      }
      // bodytracemessages
      if (patient.btMessagesBpm.length) {
        const btMessagesBpmDate = moment(
          patient.btMessagesBpm[0].createdAt
        ).valueOf()
        if (btMessagesBpmDate > bpDate) {
          const values = patient.btMessagesBpm[0].message.values
          systolic = pascalsToTorr(values.systolic)
          diastolic = pascalsToTorr(values.diastolic)
          bpDate = btMessagesBpmDate
          pulse = values.pulse
        }
      }
      recentBp = patient.btRecentBpms + patient.btMessagesRecentBpm
      bpDaysWithReadings = countDaysWithReadings(
        joinDaysWithReadings(
          patient.btBpmDaysWithReadings,
          patient.btMessagesBpmDaysWithReadings
        )
      )
    } else if (bpDevice === AD && patient.adBpm.length) {
      const measurement = standardizeAdBpm(patient.adBpm[0])
      systolic = measurement.systolic
      diastolic = measurement.diastolic
      pulse = measurement.pulse
      bpDate = measurement.ts
      recentBp = patient.adRecentBpms
      bpDaysWithReadings = countDaysWithReadings(patient.adBpmDaysWithReadings)
    } else if (bpDevice === WITHINGS && patient.withingsBpm.length) {
      // Withings
      const measurement = patient.withingsBpm[0]
      systolic = measurement.sys.value * Math.pow(10, measurement.sys.unit)
      diastolic = measurement.dia.value * Math.pow(10, measurement.dia.unit)
      pulse = measurement.pulse.value * Math.pow(10, measurement.pulse.unit)
      bpDate = measurement.created * 1000
      recentBp = patient.withingsRecentBpm
      bpDaysWithReadings = countDaysWithReadings(
        patient.withingsBpmDaysWithReadings
      )
    }

    const bpThresholdLevel = getBpThresholdLevel(
      systolic,
      diastolic,
      patient.threshold?.bloodPressure
    )

    // Weight Scale Data
    if (weightDevice === TRANSTEK && patient.ttWs.length) {
      // Transtek
      const standardWs = standardizeTranstekWs(patient.ttWs[0])
      weight = standardWs.weight
      wsDate = standardWs.ts
      if (patient.ttWs.length > 1) {
        weightChange = weight - standardizeTranstekWs(patient.ttWs[1]).weight
      }
      recentWs = patient.ttRecentWs
      wsDaysWithReadings = countDaysWithReadings(patient.ttWsDaysWithReadings)
    } else if (
      weightDevice === CARDIOWELL &&
      (patient.ws.length || patient.btMessagesWs.length)
    ) {
      // BodyTrace
      const bodytraceWs = []
      for (let i = 0; i < patient.ws.length; i++) {
        bodytraceWs.push(standardizeBtWs(patient.ws[i]))
      }
      for (let i = 0; i < patient.btMessagesWs.length; i++) {
        bodytraceWs.push(standardizeBtMessageWs(patient.btMessagesWs[i]))
      }
      bodytraceWs.sort((a, b) => b.ts - a.ts)
      weight = bodytraceWs[0].weight
      wsDate = bodytraceWs[0].ts
      if (bodytraceWs.length > 1) {
        weightChange = bodytraceWs[0].weight - bodytraceWs[1].weight
      }
      recentWs = patient.btRecentWs + patient.btMessagesRecentWs
      wsDaysWithReadings = countDaysWithReadings(
        joinDaysWithReadings(
          patient.btWsDaysWithReadings,
          patient.btMessagesWsDaysWithReadings
        )
      )
    }

    // Pulse Data
    if (patient.pulse.length) {
      // Body Trace
      spo2Date = moment(parceBerryMedOximeterTime(patient.pulse[0].time))
        .tz(patient.timeZone)
        .valueOf()
      spo2 = patient.pulse[0].spo2
      pulseRate = patient.pulse[0].pr
      recentPulse = patient.recentPulse
      pulseDaysWithReadings = countDaysWithReadings(
        patient.pulseDaysWithReadings
      )
    }

    // Glucose Data
    if (patient.glucose.length) {
      // Transtek
      glucoseDate = patient.glucose[0].ts * 1000
      glucose = patient.glucose[0].data
      recentGlucose = patient.recentGlucose
      glucoseDaysWithReadings = countDaysWithReadings(
        patient.glucoseDaysWithReadings
      )
    }

    const rt = patient.rt || 0

    const tableRow = {
      id: patient.id,
      name: patient.name,
      timezone: patient.timeZone,
      bptaken: bpDate ? new Date(bpDate) : null,
      bp: systolic + '/' + diastolic,
      bpThresholdLevel,
      pulse,
      weighttaken: wsDate ? new Date(wsDate) : null,
      weight,
      weightChange,
      spo2Date: spo2Date ? new Date(spo2Date) : null,
      spo2,
      pulseRate,
      glucose,
      glucoseDate: glucoseDate ? new Date(glucoseDate) : null,
      deviceNotificationsEnabled: patient.deviceNotificationsEnabled,
      patient,
      cellNumber: patient.cellNumber,
      email: patient.email,
      username: user,
      recentBp,
      recentWs,
      recentPulse,
      recentGlucose,
      bpDaysWithReadings,
      wsDaysWithReadings,
      pulseDaysWithReadings,
      glucoseDaysWithReadings,
      rt,
    }
    tableData.push(tableRow)
  })

  return tableData
}
